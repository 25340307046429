<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Name: </div>
                                                    <div class="mr-3 align-self-center h6"> {{this.trainer.name_and_surname}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Region :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.trainer.region.name}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Site :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.trainer.institution}} </div>
                                                </div>
                                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">S/N  :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.trainer.sn}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Responsibility :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.trainer.responsibility}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Phone :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.trainer.phone}} </div>
                                                </div>
                                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Email  :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.trainer.email}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Date of training :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.trainer.date_of_training}} </div>
                                                </div>
                                            </div>
                        </div> 
                        <b-button href="edit-trainer/{id}">Edit</b-button>                   
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import {axios_get} from "../../../../helpers/helper";


    /**
     * Form validation component
     */
    export default {
        page: {
            title: "Trainer Details",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {Layout, PageHeader},
        data() {
            return {
                title: "Trainer Details",
                trainer: null,
                id: null,
                items: [
                    {
                        text: "Trainer Details",
                        href: "/"
                    },
                    {
                        text: "Trainer Details",
                        active: true
                    }
                ],
            }
        },

        mounted() {
            this.id = this.$route.params.id
            axios_get('/trainee/'+this.id, {}, (d)=>{
                    this.trainer = d
                    console.log(this.trainer)
                }, (e)=>{
                    console.log('error', e)
                }, ()=>{
                    console.log('final')
                });
            }
    }
</script>

<style scoped>

</style>